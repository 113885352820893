import { useCurrentUser } from "@wpf-admin-ui/contexts";

interface Features {
  writerCompetition: boolean;
  feedbackAndOverallFeedback: boolean;
  resultAnnouncement: boolean;
  listingAdmin: boolean;
  templateFunction: boolean;
}

const defaultFeatures = {
  enabledFeatures: new Set<string>(),
};

export function useFeatures(): Features {
  const { enabledFeatures } = useCurrentUser() ?? defaultFeatures;
  return {
    writerCompetition: enabledFeatures.has("writer_competition"),
    feedbackAndOverallFeedback: enabledFeatures.has("feedback_and_overall_feedback"),
    resultAnnouncement: enabledFeatures.has("result_announcement"),
    listingAdmin: enabledFeatures.has("listing_admin"),
    templateFunction: enabledFeatures.has("template_function"),
  };
}

import React, { useCallback } from "react";
import { useFeatures } from "@wpf-admin-ui/hooks/useFeatures";
import { Box, IconButton, Image, useDisclosure, VStack } from "@chakra-ui/react";
import { FiBookOpen, FiFileText } from "react-icons/fi";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight, MdOutlineCreate } from "react-icons/md";
import MenuItem from "./MenuItems";
import { RiAccountBoxLine } from "react-icons/ri";
import token from "@sheinc/design-tokens";
import { Link } from "@wpf-admin-ui/components/essentials/link";

export const SideMenu: React.VFC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleToggleMenu = useCallback(() => {
    isOpen ? onClose() : onOpen();
  }, [isOpen, onClose, onOpen]);
  const { templateFunction } = useFeatures();
  const menuItems = [
    { icon: <RiAccountBoxLine />, label: "タレントDB", to: "/search" },
    { icon: <MdOutlineCreate />, label: "募集作成", to: "/projects/new" },
    { icon: <FiBookOpen />, label: "募集一覧", to: "/projects" },
    ...(templateFunction ? [{ icon: <FiFileText />, label: "テンプレート", to: "/project-templates" }] : []),
  ];

  return (
    <Box display="flex" h="100vh" zIndex={2} position="sticky" top={0}>
      <Box
        display="flex"
        flexDirection="column"
        bg="white"
        w={isOpen ? "210px" : "58px"}
        transition="width 200ms"
        boxShadow="md"
        pl={0.3}
        position="relative"
      >
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            onClick={handleToggleMenu}
            aria-label="Side Menu"
            icon={isOpen ? <MdKeyboardDoubleArrowLeft fontSize={24} /> : <MdKeyboardDoubleArrowRight fontSize={24} />}
            color={token.color.function.primaryDefault.value}
            background="none"
            right={isOpen ? "0" : "6px"}
            position="absolute"
            zIndex={5}
            mt="6px"
          />
        </Box>
        <Box height="64px" py={token.space["500"].value} gap={token.space["200"].value}>
          <Link href="/">
            <Image
              paddingX={token.space["500"].value}
              src="/images/logo.svg"
              alt="logo"
              cursor="pointer"
              visibility={isOpen ? "visible" : "hidden"}
              height="32px"
            />
          </Link>
        </Box>
        <VStack w="100%" h="100vh" align="start" overflow="hidden">
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              icon={item.icon}
              label={isOpen ? item.label : ""}
              to={item.to}
              isOpen
              activeColor={token.color.primitive.shegreen50.value}
            />
          ))}
        </VStack>
      </Box>
    </Box>
  );
};
